import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './components/store'
import './styles/app.scss'

Vue.config.productionTip = false

import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics";

// Initialize Cloud Firestore through Firebase
const firebaseApp = initializeApp({
  apiKey: "AIzaSyD56wokMh-8F7nXhc2wmjMk5sqt0E3ZS3M",
  authDomain: "seerow-xmas-2021.firebaseapp.com",
  databaseURL: "https://seerow-xmas-2021-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "seerow-xmas-2021",
  storageBucket: "seerow-xmas-2021.appspot.com",
  messagingSenderId: "144491775151",
  appId: "1:144491775151:web:b7703aad70f513d95e805a",
  measurementId: "G-SGYXWYMY8K"
});

getAnalytics(firebaseApp);