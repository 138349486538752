import Vue from 'vue'
import Vuex from 'vuex'
import cookie from './modules/cookie'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    cookie,
  },
  strict: debug
})
