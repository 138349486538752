import * as types from '../mutation-types'

const state = {
  toppings: [],
}

const getters = {
  toppings: state => state.toppings,
  numberOfToppings: state => state.toppings.length
}

const actions = {
  updateToppings({commit}, array) {
    commit(types.UPDATE_TOPPINGS, array)
  },
}

const mutations = {
  [types.UPDATE_TOPPINGS](state, array) {
    state.toppings = array
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}